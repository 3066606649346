import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import AppRouter from './Router.js'
import { Lines } from 'react-preloaders2'
import { ParallaxProvider } from 'react-scroll-parallax'
import 'react-app-polyfill/stable'

ReactDOM.render(
  <React.StrictMode>
    <ParallaxProvider>
      <AppRouter />
      <Lines color={'#000'} background={'#000'}/>
    </ParallaxProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
