import React, { useEffect } from 'react'

function Impressum () {
  useEffect(() => {
    document.body.classList.add('impressum')
    document.body.classList.add('light-mode')

    return function cleanup () {
      document.body.classList.remove('impressum')
      document.body.classList.remove('light-mode')
    }
  }, [])
  return (
    <div className="content">
      <div className="row first">
        <div className="row-single">
          <h2>Impressum</h2>
          <div className="divider"></div>
          <p className="intro"> Die interaktive Erzählung  »Im Dunkeln –  Ein Leuchten« entstand im Rahmen der Ausstellung »Trotz allem« Fred Uhlman – ein jüdisches Schicksal im Graphikkabinett der Staatsgalerie Stuttgart.</p>
        </div>
      </div>
      <div className="row">
        <div className="row-single">
          <h3>Mitwirkende</h3>
          <p>
            <ul>
              <li><b>Kuratorinnen:</b> Dr. Corinna Höper, Johanna Poltermann</li>
              <li><b>Digitalidee, Konzept, Projektleitung:</b> Ilona Hoppe</li>
              <li><b>Digitalteam:</b> Ilona Hoppe, Christoph Wetzel, Dr. Helga Huskamp, Johannes Goss, Jan Roth</li>
              <li><b>Bildung und Vermittlung:</b> Susanne Kohlheyer, Anna Arnold, Steffen Egle</li>
              <li><b>Stimmen:</b> Lea Brückner, Berenike Beckhaus, Mareike Köhler, Marcus Westhoff, Aurelia Orel, Mira Schrenk, Ildikó Fillies</li>
            </ul>
          </p>
          <p>Ein herzlicher Dank gilt der Hochschule für Musik und Darstellende Künste Stuttgart, Frau Prof. Müller und den Studierenden des Studiengangs Sprechkunst sowie Kolleginnen und Kollegen für die Zusammenarbeit.</p>

          <h3>Copyright</h3>
          <p>Die sechs abgebildeten Zeichnungen Fred Uhlmans stammen aus der Zeichnungsfolge »Captivity«<br/>© The Estate of Fred Uhlman</p>

          <p>
            <ul>
              <li><b>Zeichnung 1:</b> Schlachtfeld (C 1959 /255)</li>
              <li><b>Zeichnung 2:</b> Menschen hinter Stacheldraht (C 1950 /252)</li>
              <li><b>Zeichnung 3:</b> Skeletthügel (C 1950 /259)</li>
              <li><b>Zeichnung 4:</b> Landschaft mit Erhängten (C 1950 /263)</li>
              <li><b>Zeichnung 5:</b> Die toten Feldherren (C 1950 /284)</li>
              <li><b>Zeichnung 6:</b> Totentanz zu Dritt (C 1950 /287)</li>
            </ul>
          </p>
          <p>Alle Zeichnungen entstanden 1940 im Zyklus »Captivity«. Sie wurden von Fred Uhlman mit Feder in Schwarz, Pinsel in Grau und Schwarz gezeichnet. Insgesamt 38 Werke dieser Zeichnungsfolge schenkte Fred Uhlman 1950 der Staatsgalerie Stuttgart.
Die beiden weiteren Fotografien zeigen den Künstler Fred Uhlman in seinem Maleratelier Downshire Hill Studio nahe London sowie die Stolpersteine des Künstlers Gunter Demnig, die zum Gedenken der Familie Uhlman auf dem Gehweg vor dem ehemaligen Wohnhaus in der Hölderlinstraße 57 in Stuttgart angebracht sind.</p>
        </div>
      </div>
    </div>
  )
}
export default Impressum
