import React, { useState, useEffect } from 'react'
import ClickIcon from './assets/icons/tap_click.svg'
import TapIcon from './assets/icons/tap_move.svg'
import SoundIcon from './assets/icons/speaker.svg'
import { isMobile } from 'react-device-detect'

function Message (props) {
  const { hidden, start } = props
  const [showTutorial, setShowTurorial] = useState(false)
  const [showStart, setShowStart] = useState(true)

  var tutorialIcon = ClickIcon
  if (isMobile) {
    tutorialIcon = TapIcon
  }

  useEffect(() => {
    if (hidden === true && showTutorial === false) {
      handleShowMessage()
    }
  }, [hidden])

  const handleShowMessage = () => {
    setTimeout(function () {
      setShowTurorial(true)
    }, 3000)

    setTimeout(function () {
      setShowTurorial(false)
    }, 12000)
  }
  const handleStart = () => {
    document.body.classList.add('clicked')
    setShowStart(false)
    start()
  }

  return <>
    <div className={`message ${showTutorial}`}>
      <div className={`tutorial ${showTutorial}`}>
        <img src={tutorialIcon} width="72" height="72"/>
        <p>Bewege deinen Cursor oder deinen Finger, um das Bild zu beleuchten</p>
      </div>
      <div className={`startsound ${showStart}`}>
        <img src={SoundIcon} width="64" height="64"/>
        <p>Für das beste Erlebnis aktiviere bitte den Ton deines Gerätes</p>
        <button className="btn startambient" onClick={handleStart}>Alles klar</button>
      </div>
    </div>
  </>
}
export default Message
