import React from 'react'
import './App.css'
// import Title from './Title.js'
import Drawings from './Drawings.js'

function Home () {
  return (
    <div className="App">
      <Drawings />
    </div>
  )
}

export default Home
