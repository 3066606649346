import React from 'react'
import { MemoryRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './Home.js'
import MainMenu from './MainMenu.js'
import Impressum from './Impressum.js'
// import Drawings from './Drawings.js'
import Uhlman from './Uhlman.js'
import Links from './Links.js'
// import { useIdleTimer } from 'react-idle-timer'

export default function BasicExample () {
  /*
  const handleOnIdle = event => {
    console.log('last active', getLastActiveTime())
    window.location.reload()
  }

  const handleOnActive = event => {
  }

  const handleOnAction = event => {
  }

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  }) */

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/zeichnungen">
            <Home />
          </Route>
          <Route path="/fred-uhlman">
            <Uhlman />
          </Route>
          <Route path="/links">
            <Links />
          </Route>
          <Route path="/impressum">
            <Impressum />
          </Route>
        </Switch>
        <MainMenu />
      </Router>
    </>
  )
}
