import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DrawingImage1 from './assets/drawings/01_schlachtfeld_ballon.jpg'
import DrawingImage2 from './assets/drawings/02_MenschenHinterStacheldraht.jpg'
import DrawingImage3 from './assets/drawings/03_Skeletthuegel.jpg'
import DrawingImage4 from './assets/drawings/04_LandschaftMitErhaengten.jpg'
import DrawingImage5 from './assets/drawings/05_ToteFeldherren.jpg'
import DrawingImage6 from './assets/drawings/06_TotentanzZuDritt.jpg'
import DrawingImage7 from './assets/drawings/07_Fotografien.jpg'
import TitleAnimation from './Title.js'
import ParticlesStyle from './ParticlesStyle.js'
import Subtitles from './Subtitles.js'
import Message from './Message.js'
import { isMobile } from 'react-device-detect'

import useMousePosition from './MousePostion.js'
import useWindowDimensions from './WindowDimensions.js'
import ReactHowler from 'react-howler'
import sound0Mp3 from '../src/assets/sounds/00.mp3'
import sound1Mp3 from '../src/assets/sounds/01.mp3'
import sound2Mp3 from '../src/assets/sounds/02.mp3'
import sound3Mp3 from '../src/assets/sounds/03.mp3'
import sound4Mp3 from '../src/assets/sounds/04.mp3'
import sound5Mp3 from '../src/assets/sounds/05.mp3'
import sound6Mp3 from '../src/assets/sounds/06.mp3'
import sound7Mp3 from '../src/assets/sounds/07.mp3'
import soundLightMp3 from '../src/assets/sounds/light.mp3'
import sound0Ogg from '../src/assets/sounds/00.ogg'
import sound1Ogg from '../src/assets/sounds/01.ogg'
import sound2Ogg from '../src/assets/sounds/02.ogg'
import sound3Ogg from '../src/assets/sounds/03.ogg'
import sound4Ogg from '../src/assets/sounds/04.ogg'
import sound5Ogg from '../src/assets/sounds/05.ogg'
import sound6Ogg from '../src/assets/sounds/06.ogg'
import sound7Ogg from '../src/assets/sounds/07.ogg'
// import soundLightOgg from '../src/assets/sounds/light.ogg'
import wdh1 from '../src/assets/sounds/wdh1.mp3'
import wdh2 from '../src/assets/sounds/wdh2.mp3'
import wdh3 from '../src/assets/sounds/wdh3.mp3'
import wdh4 from '../src/assets/sounds/wdh4.mp3'
import wdh5 from '../src/assets/sounds/wdh5.mp3'
import wdh6 from '../src/assets/sounds/wdh6.mp3'

function Drawings () {
  const { x, y } = useMousePosition()
  const { height, width } = useWindowDimensions()
  const playerRef = useRef()
  const [seek, setSeek] = useState(0.0)
  const [playing, setPlaying] = useState(false)
  const [mute, setMute] = useState(false)
  const [playEffect, setplayEffect] = useState(false)
  const [hideStart, setHideStart] = useState(false)
  const [titleAnimation, setTitleAnimation] = useState(false)
  const [startButton, setStartButton] = useState(false)
  const [currentInt, setCurrentInt] = useState(0)
  const [currentEffect, setCurrentEffect] = useState(0)
  const [imageAnimation, setImageAnimation] = useState(false)
  const [showSubtitle, SetShowSubtitle] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const history = useHistory()

  const sources = [[sound0Ogg, sound0Mp3], [sound1Ogg, sound1Mp3], [sound2Ogg, sound2Mp3], [sound3Ogg, sound3Mp3], [sound4Ogg, sound4Mp3], [sound5Ogg, sound5Mp3], [sound6Ogg, sound6Mp3], [sound7Ogg, sound7Mp3]]
  const images = [DrawingImage1, DrawingImage1, DrawingImage2, DrawingImage3, DrawingImage4, DrawingImage5, DrawingImage6, DrawingImage7]
  const effects = [soundLightMp3, wdh1, wdh2, wdh3, wdh4, wdh5, wdh6]
  const titles = ['Intro', 'Schlachtfeld', 'Menschen hinter Stacheldraht', 'Skeletthügel', 'Landschaft mit Erhängten', 'Die toten Feldherren', 'Totentanz zu dritt', 'Fotografien']
  var spotStartSizes = [0, 12, 10, 15, 15, 15, 12, 12]
  const spotEndSizes = [0, 45, 50, 55, 45, 50, 30, 45]
  const spotTimings = [0, 170, 170, 170, 170, 170, 170, 170]

  if (isMobile) {
    spotStartSizes = [0, 30, 20, 30, 30, 30, 24, 24]
  }

  useEffect(() => {
    if (playEffect !== true && seek > spotTimings[currentInt] + 60.0 && seek < spotTimings[currentInt] + 61.0) {
      handlePlayEffect()
      console.log('1effekt fired:' + seek)
    }

    if (playEffect !== true && seek > 160.0 && seek < 161.0 && currentInt === 2) {
      handlePlayEffect()
      console.log('2effekt fired:' + seek)
    }

    if (playEffect !== true && seek > 149.0 && seek < 150.0 && (currentInt === 4 || currentInt === 5)) {
      handlePlayEffect()
      console.log('3effekt fired:' + seek)
    }

    if (seek > spotTimings[currentInt] && seek < spotTimings[currentInt] + 1) {
      setShowNext(true)
    }
  }, [seek])

  useEffect(() => {
    setShowNext(false)
    document.body.classList.add('drawing')
    return function cleanup () {
      document.body.classList.remove('drawing')
    }
  }, [])

  var spotsize = spotStartSizes[currentInt]
  var maxSpotsize = spotEndSizes[currentInt]
  var playtime = spotTimings[currentInt]

  if (playerRef.current !== undefined) {
    window.requestAnimationFrame(() => setSeek(playerRef.current?.seek().toFixed(2)))
    spotsize = (seek / playtime * maxSpotsize) + spotsize
  }

  const styleframe = {
    transform: `translate(${-x / width * 100}%, ${-y / height * 100}%)`,
    backgroundImage: `url(${images[currentInt]})`,
    left: `${width / 2}px`,
    top: `${height / 2}px`
  }
  const stylespot = {
    background: `radial-gradient(circle at ${x}px ${y}px, transparent, rgba(0,0,0,.96) ${spotsize}%`
  }

  const handlePause = () => {
    setPlaying(!playing)
  }

  const handleMute = () => {
    setMute(!mute)
  }

  const handleAmbient = () => {
    setPlaying(true)
    playerRef.current.howler.fade(0, 1, 3000)
  }

  const handleSubtitle = () => {
    SetShowSubtitle(!showSubtitle)
  }

  const handleEndTrack = () => {
    playerRef.current.howler.seek(180)
  }

  const goHome = () => {
    window.location.reload()
  }

  const handleStartApplication = () => {
    setTitleAnimation(!titleAnimation)
    setStartButton(!startButton)
    handlePlayEffect()
    handleNext()

    setTimeout(function () {
      setHideStart(true)
    }, 3000)
  }

  const handleNext = () => {
    setShowNext(false)
    if (currentInt === 7) {
      history.push('/links')
    }

    if (currentInt < 7) {
      playerRef.current.howler.fade(1, 0, 1000)
      fadeImageAnimation()
      setTimeout(function () {
        setCurrentInt(currentInt + 1)
        setPlaying(true)
        changeTimings()
        playerRef.current.howler.fade(0, 1, 1000)
      }, 1000)
    }
  }

  const changeTimings = () => {
    spotsize = spotStartSizes[currentInt]
    maxSpotsize = spotEndSizes[currentInt]
    playtime = spotTimings[currentInt]
  }

  const handleBack = () => {
    if (currentInt === 1) {
      goHome()
    }
    if (currentInt > 1) {
      changeTimings()
      fadeImageAnimation()
      playerRef.current.howler.fade(1, 0, 1000)
      setTimeout(function () {
        setCurrentInt(currentInt - 1)
        playerRef.current.howler.fade(0, 1, 1000)
      }, 1000)
    }
  }

  const handleStopEffect = () => {
    setplayEffect(false)
  }

  const handlePlayEffect = () => {
    if (currentInt === 0) {
      setCurrentEffect(0)
      setplayEffect(true)
    } else {
      setCurrentEffect(currentInt)
      setplayEffect(true)
    }
  }

  const fadeImageAnimation = () => {
    setImageAnimation(true)
    setTimeout(function () {
      setImageAnimation(false)
    }, 2100)
  }

  return (
    <>
      <ParticlesStyle />
      <ReactHowler
        src={sources[currentInt]}
        ref={(ref) => (playerRef.current = ref)}
        playing={playing}
        preload = {true}
        mute ={mute}
        loop = {false}
        onEnd = {handleEndTrack}
      />
      <ReactHowler
        src={effects[currentEffect]}
        playing={playEffect}
        preload = {true}
        loop = {false}
        onEnd = {handleStopEffect}
        mute ={mute}
      />
      <div className={`interactionCanvas ${hideStart}`}>
        <div className={`drawframe ${imageAnimation}`} style={styleframe}></div>
        <div className="spotlight" style={stylespot}></div>
        <div className="canvastools">
          <div className="drawtitle"><p>{currentInt} / 7</p><p>{titles[currentInt]}</p></div>
          <Subtitles time={seek} track={currentInt} active={showSubtitle}></Subtitles>
          <div id="drawnavbar" className="drawnavbar">
            <div className="btn back" onClick={handleBack}><i className="arrow left"></i>
              {currentInt === 1
                ? <span>Startseite</span>
                : <span>Zurück</span>
              }
            </div>
            <div className={`btn plainicon mute ${mute}`} onClick={handleMute} ><i></i></div>
            <div className={`btn icon play ${playing}`} onClick={handlePause}><div className={'audiobutton'}></div></div>
            <div className={`btn plainicon ut ${showSubtitle}`} onClick={handleSubtitle} ><i></i></div>
            <div className={`btn next ${showNext}`} onClick={handleNext}>
              {currentInt === 7
                ? <span>Links</span>
                : <span>Weiter</span>
              }
              <i className="arrow right"></i>
            </div>
          </div>
          <div className="progessbg">
            <div className="progessbar" style={{ width: seek / playtime * 100 + '%' }}></div>
          </div>
        </div>
      </div>
      <header className={`App-header ${hideStart}`}>
        <TitleAnimation hidden={titleAnimation} start={handleStartApplication}></TitleAnimation>
        <div className={`startbtn ${startButton}`}>
          <button className="magnet" onClick={handleStartApplication}>
          Start
            <span className="play"></span>
          </button>
        </div>
      </header>
      <Message hidden={titleAnimation} start={handleAmbient}></Message>
    </>
  )
}
export default Drawings
