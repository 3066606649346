import React, { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import ParticleEffectButton from 'react-particle-effect-button'
import { Link } from 'react-router-dom'

export default function Title (props) {
  const { hidden, start } = props
  useEffect(() => {
    if (hidden === true) {
      hideTitle()
    }
  }, [hidden])

  const [hiddenTitle, setHiddenTitle] = useState(false)
  const [hiddenAuthor, setHiddenAuthor] = useState(false)
  const [hiddenSubtitle, setHiddenSubtitle] = useState(false)
  // eslint-disable-next-line no-unused-vars
  function hideTitle () {
    setHiddenAuthor(!hiddenTitle)

    setTimeout(function () {
      setHiddenSubtitle(!hiddenTitle)
    }, 200)

    setTimeout(function () {
      setHiddenTitle(!hiddenTitle)
    }, 1000)
  }
  // eslint-disable-next-line no-unused-vars
  function showTitle () {
    setHiddenAuthor(!hiddenTitle)

    setTimeout(function () {
      setHiddenSubtitle(!hiddenTitle)
    }, 200)

    setTimeout(function () {
      setHiddenTitle(!hiddenTitle)
    }, 1000)
  }

  return <>
    <ParticleEffectButton
      color='#fff'
      hidden={hiddenAuthor}
      canvasPadding={100}
      particlesAmountCoefficient = {1}
      oscillationCoefficient = {20}
      duration={1000}
    >
      <h3><Link to="/fred-uhlman">Fred Uhlman</Link></h3>
    </ParticleEffectButton>
    <ParticleEffectButton
      color='#fff'
      hidden={hiddenTitle}
      canvasPadding={100}
      particlesAmountCoefficient = {1}
      oscillationCoefficient = {20}
      duration={1000}
    >
      <h1 onClick={start}><span>Im Dunkeln –<br/>Ein Leuchten</span></h1>
    </ParticleEffectButton>
    <ParticleEffectButton
      color='#fff'
      hidden={hiddenSubtitle}
      canvasPadding={100}
      particlesAmountCoefficient = {1}
      oscillationCoefficient = {20}
      duration={1000}
    >
      <span className="divider"></span>
      <p className="subline">Eine interaktive Erzählung zur Zeichnungsfolge «Captivity»</p>
    </ParticleEffectButton>
  </>
}
