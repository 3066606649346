import React, { useState } from 'react'
import logo from '../src/assets/cd/logo.svg'
import { Link } from 'react-router-dom'
import useMousePosition from './MousePostion.js'

export default function MainMenu () {
  const [toggleState, setToggleState] = useState('close')
  const { x, y } = useMousePosition()

  const styles = {
    transform: `translate(${x}px, ${y}px)`
  }

  function toggle () {
    setToggleState(toggleState === 'close' ? 'open' : 'close')
  }

  const goHome = () => {
    window.location.reload()
  }

  return <>
    <div id="overlay" className={`container switch ${toggleState}`} onClick={toggle}>
      <div className="container">
        <div className="left-half">
          <ul className="mainmenu">
            <li className="home"><a onClick={goHome}>Startseite</a></li>
            <li className="home"><a onClick={goHome}>Zeichnungen</a></li>
            <li className="uhlman"><Link to="/fred-uhlman">Fred Uhlman</Link></li>
            <li className="links"><Link to="/links">Links</Link></li>
          </ul>
          <div className="overlayFooter">
            <Link to="/impressum">Impressum</Link>
          </div>
        </div>
        <div className="right-half">
          <div className="description">
            <p>
            In der Ausstellung »Trotz allem« Fred Uhlman – ein jüdisches Schicksal zeigt die Staatsgalerie Stuttgart 2021 erstmalig die Werke des bislang zu wenig beachteten »verlorenen Sohn« Stuttgarts. Angelehnt an seine Zeichnungen aus dem Internierungslager entstand die interaktive Erzählung »Im Dunkeln – Ein Leuchten«. Sie bringt den Künstler, seine Zeichnungen und die Geschichte seiner Zeit wortwörtlich ans Licht.
            </p>
            <p><br></br></p>
            <p><Link className="link sbtn" to="/fred-uhlman">Mehr über Fred Uhlman<i className="arrow right"></i></Link></p>
          </div>
        </div>
      </div>
    </div>

    <div className="menu" onClick={toggle}>
      <div id="nav-icon3" className={`switch ${toggleState}`} >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div className={`logo ${toggleState}`}>
      <a onClick={goHome}>
        <img src={logo} width="50" height="223"></img>
      </a>
    </div>
    <div id="cursor--small" className={`cursor cursor--small ${toggleState}`} style={styles}></div>
  </>
}
