/* eslint-disable import/no-duplicates */
import React, { useState, useEffect } from 'react'
import subvtt1 from './assets/subtitles/01.json'
import subvtt2 from './assets/subtitles/02.json'
import subvtt3 from './assets/subtitles/03.json'
import subvtt4 from './assets/subtitles/04.json'
import subvtt5 from './assets/subtitles/05.json'
import subvtt6 from './assets/subtitles/06.json'
import subvtt7 from './assets/subtitles/07.json'

const subtitles = [subvtt1, subvtt2, subvtt3, subvtt4, subvtt5, subvtt6, subvtt7]

function Subtitles (props) {
  const [output, setOutput] = useState('Anwendung wird geladen')
  const [time, setTime] = useState(0.0)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (props.time !== time && props.track !== 0) {
      handleUpdateSubtitle(props.time, subtitles[props.track - 1])
      setTime(props.time)
      setVisible(props.active)
    }
  }, [props.time, props.active])

  const handleUpdateSubtitle = (currentTime, subtitle) => {
    const result = subtitle.find(sub => (sub.start <= currentTime && sub.end > currentTime))
    if (result !== undefined) {
      setOutput(result.text)
    } else {
      setOutput('')
    }
  }

  return <>
    <p className={`subtitle ${visible}`}>{output}</p>
  </>
}
export default Subtitles
