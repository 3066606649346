import { useState, useEffect } from 'react'
import useWindowDimensions from './WindowDimensions.js'
import { isMobile } from 'react-device-detect'

const useMousePosition = () => {
  const { height, width } = useWindowDimensions()
  const [mousePosition, setMousePosition] = useState({ x: width / 2, y: height / 2 })

  const updateMousePosition = ev => {
    if (!isMobile) {
      setMousePosition({ x: ev.clientX, y: ev.clientY })
    }
  }

  const updateTouchPosition = ev => {
    setMousePosition({ x: ev.touches[0].pageX, y: ev.touches[0].pageY - 70 })
  }

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition)
    window.addEventListener('touchmove', updateTouchPosition)

    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
      window.removeEventListener('touchmove', updateTouchPosition)
    }
  }, [])

  return mousePosition
}

export default useMousePosition
