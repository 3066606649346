import React from 'react'
import Particles from 'react-particles-js'

export default function ParticleStyle () {
  return <>
    <Particles
      className="particles"
      params={{
        particles: {
          number: {
            value: 30,
            density: {
              enable: false
            }
          },
          size: {
            value: 3,
            random: true,
            anim: {
              speed: 4,
              size_min: 0.3
            }
          },
          line_linked: {
            enable: false
          },
          move: {
            random: true,
            speed: 1,
            direction: false,
            out_mode: 'out'
          }
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: 'bubble'
            },
            onclick: {
              enable: true,
              mode: 'repulse'
            }
          },
          modes: {
            bubble: {
              distance: 200,
              duration: 2,
              size: 0,
              opacity: 0
            },
            repulse: {
              distance: 200,
              duration: 4
            }
          }
        }
      }} />
  </>
}
