import React, { useEffect } from 'react'
// import { Parallax } from 'react-scroll-parallax'
// import ParticlesStyle from './ParticlesStyle.js'
import image1 from './assets/images/uhmann_01.jpg'
import image2 from './assets/images/uhmann_02.jpg'
import image3 from './assets/images/uhmann_03.jpg'
import DrawingImage1 from './assets/drawings/01_schlachtfeld_ballon.jpg'

function Uhlman () {
  useEffect(() => {
    document.body.classList.add('uhlman-detail')
    document.body.classList.add('light-mode')

    return function cleanup () {
      document.body.classList.remove('uhlman-detail')
      document.body.classList.remove('light-mode')
    }
  }, [])

  const goHome = () => {
    window.location.reload()
  }

  return (
    <>
      <div className="content">
        <div className="row first">
          <div className="row-single">
            <h2>Fred Uhlman</h2>
            <span className="divider"></span>
            <p className="intro">Totentänze, Schlachtfelder und ein kleines Mädchen mit leuchtendem Ballon – Fred Uhlmans Zeichnungen aus dem Internierungslager beleuchten ein düsteres Stück Geschichte, spenden jedoch zugleich einen Funken Hoffnung. Seine Werke, sein Leben und seine persönliche Familiengeschichte stehen symbolisch für zahlreiche jüdische Schicksale.</p>
          </div>
        </div>

        <div className="row">
          <div className="row-left-half">
            <figure>
              <img width="370" height="350" src={image1} />
              <figcaption>Fred Uhlman mit seiner kleinen Schwester Erna, 1904</figcaption>
            </figure>
          </div>
          <div className="row-right-half mauto">
            <p>Manfred (Fred) Uhlman wird 1901 in Stuttgart geboren, wo er mit seinen Eltern und einer jüngeren Schwester aufwächst. Er besucht das Eberhard-Ludwigs-Gymnasium und arbeitet ab 1927 als Rechtsanwalt in Stuttgart. Die drohende Verfolgung als Jude zwingt ihn, im März 1933 Stuttgart zu verlassen und nach Paris zu emigrieren. </p>
          </div>
        </div>
        <div className="row">
          <div className="row-single">
            <p className="editor">Ein befreundeter Jurist lässt ihn warnen:</p>
            <blockquote> »Wenn sie <i>Uhlmännle</i> sehen, sagen Sie ihm, dass es in Paris <i>jetzt</i> sehr schön ist. Sagen Sie ihm: <i>jetzt.</i>«¹</blockquote>
          </div>
        </div>
        <div className="row">
          <div className="row-left-half mauto">
            <p>Im französischen Exil verdient Uhlman seinen Lebensunterhalt unter anderem als Kunsthändler. Hier entdeckt er im künstlerischen Umfeld selbst Freude an der Malerei und beginnt zu malen.
Auf einer Reise nach Spanien lernt Uhlman 1936 seine zukünftige Frau Diana Croft kennen. In ihr Heimatland Großbritannien zieht das Paar kurze Zeit später, wo Uhlman die Malerei fortsetzt. Kurz vor Beginn des Zweiten Weltkrieges gründet Uhlman die »Free German League of Culture – Freier Deutscher Künstlerbund«, die deutsche Geflüchtete in England vereinen soll.</p>
            <p>Während des Zweiten Weltkrieges, im Juni 1940, wird Uhlman verhaftet und wie alle »feindlichen Ausländer« in England in den Lagern in Ascot und auf der Isle of Man interniert. Während seiner sechsmonatigen Haftzeit schafft Uhlman rund 150 Zeichnungen, unter dem Titel »Captivity« (Gefangenschaft).</p>
          </div>
          <div className="row-right-half">
            <figure>
              <img width="640" height="960" src={image3} />
              <figcaption>Fred Uhlman in seinem Atelier Downshire Hill Studio</figcaption>
            </figure>
          </div>
        </div>

        <div className="row">
          <div className="row-left-half">
            <figure>
              <img width="1920" height="1616" src={DrawingImage1} />
              <figcaption>Schlachtfeld“ aus der Zeit seiner Internierung, Fred Uhlman, 1940</figcaption>
            </figure>

          </div>
          <div className="row-right-half mauto">
            <p>Den gesamten Zyklus zeichnet der Künstler in schwarzer Feder und grauem und schwarzem Pinsel. Er wirft dabei einen düsteren und symbolischen Blick auf die Zeit, die Gräuel auf den Schlachtfeldern und zeigt antikirchliche Visionen der gegenwärtigen und kommenden Zeit – einen modernen »Totentanz«. Hoffnung verheißen teilweise Blumen, die aus Grabhügeln und Schädeln wachsen sowie ein kleines Mädchen mit einem Luftballon, das durch apokalyptische Szenen wandert: Auf einigen der Blätter findet sich die Widmung an seine am 3. Juli 1940 geborene Tochter. An sie erinnert das Kind auf den Zeichnungen.
In England lebt und arbeitet Uhlman auch nach seiner Entlassung als Künstler und Schriftsteller.</p>
          </div>
        </div>

        <div className="row">
          <div className="row-single">
            <p className="editor">Nach dem Krieg besucht er seine Heimatstadt Stuttgart und schrieb:</p>
            <blockquote>»Als ich nach dem Krieg wieder nach Stuttgart kam, fühlte ich mich dort vollkommen fremd. Die Stadt war ›wie ein großer Friedhof im Mondenschein‹, ich selbst ein Geist unter Geistern. Die Fenster, die sich mir einstmals geöffnet hatten, waren leer. Ich wartete und niemand kam.«²</blockquote>
          </div>
        </div>

        <div className="row">
          <div className="row-left-half mauto">
            <p>Uhlmans Eltern waren im Konzentrationslager Theresienstadt umgekommen und seine Schwester hatte auf dem Weg nach Auschwitz Suizid begangen. Durch dieses familiäre Schicksal bleibt sein Verhältnis zum Heimatland zwiespältig, jedoch schlägt er über die Zeit eine versöhnende Brücke:
1950 schenkt er 38 Werke der Zeichnungsfolge »Captivity« der Staatsgalerie Stuttgart, mit dem Untertitel »Zeichnungen zur Zeit (Staat und Kirche)«.</p>
          </div>
          <div className="row-right-half">
            <figure>
              <img width="370" height="350" src={image2} />
              <figcaption>Familie Uhlman mit den Kindern Fred und Erna in Stuttgart</figcaption>
            </figure>
          </div>
        </div>

        <div className="row">

          <div className="row-left-half">
            <blockquote>»Der Stadt Stuttgart. Trotz Allem«</blockquote>
          </div>
          <div className="row-right-half">
            <p>1960 erscheint seine Autobiographie »The Making of an Englishman« (»Erinnerungen eines Stuttgarter Juden«). Ein Exemplar schenkt er dem Stuttgarter Rathaus mit der handschriftlichen Widmung »Der Stadt Stuttgart. Trotz Allem«.</p>
            <p>Bekannt wurde Uhlman auch durch seine Novelle »Reunion«, 1971 (»Der wiedergefundene Freund«). Bis zu seinem Tod im Jahr 1985 arbeitet Fred Uhlman als Künstler und Schriftsteller in London.</p>
          </div>
        </div>

        <div className="row">
          <div className="row-single">

            <blockquote>„(…) Ich habe Ihnen heute ein Paket mit etwa 20–30 Zeichnungen geschickt. Bitte lassen Sie mich wissen ob es angekommen ist. Natürlich brauchen Sie nicht alles zu behalten. Was ihnen nicht gut vorkommt können Sie gerne wieder zurückschicken. – oder Sie bringen sie selbst nach London anno 1951
Ihr Fred U.“</blockquote>
            <p className="editor">Brief Fred Uhlman an Theodor Musper, Direktor der Staatsgalerie, 21.10.1950</p>

          </div>
        </div>

        <div className="row">
          <div className="row-single">

            <blockquote>„(…) Ich weiss es sehr hoch zu schätzen, dass Sie den Vorschlag machen, uns das eine oder andere Ihrer Schwarz-Weiss-Blätter für die Graph. Sammlung zu überlassen. Das Motto: »als Geschenk des verlorenen Sohnes« soll mir willkommen sein, wenn ich auch finde, dass es auf diesen Fall nicht ganz passt, ja beinahe die Wahrheit auf den Kopf stellt. Verirrt haben sich andere. Aber so verstanden, dass wir Sie wieder so halb zu uns rechnen dürfen, würde es mir gut gefallen.“</blockquote>
            <p className="editor">Brief Theodor Musper an Fred Uhlman, 18.10.1950</p>

          </div>
        </div>

        <div className="row">
          <div className="row-single">
            <p className="intro">2021 zeigt die Staatsgalerie erstmalig die Schenkung des Künstlers in der Ausstellung »Trotz allem« Fred Uhlman – ein jüdisches Schicksal. Angelehnt an seine Zeichnungen entstand die interaktive Erzählung »Im Dunkeln – Ein Leuchten«. Sie bringt den Künstler, seine Zeichnungen und die Geschichte seiner Zeit wortwörtlich ans Licht.</p>
            <p><a className="link sbtn" onClick={goHome}>Zurück zur Startseite<i className="arrow right"></i></a></p>
          </div>
        </div>

        <div className="row">
          <div className="row-single">
            <p className="small"> 1,2 Fred Uhlman: The Making of an Englishman, London 1960; deutsche Ausgabe: The Making of an Englishman. Erinnerungen eines Stuttgarter Juden, hg. von Manfred Schmid, Stuttgart 1992 (Veröffentlichungen des Archivs der Stadt Stuttgart, Bd. 56), Zürich, S164.</p>
            <p></p>
          </div>
        </div>

      </div>
    </>
  )
}
export default Uhlman
