import React, { useEffect } from 'react'
import ResponsiveGallery from 'react-responsive-gallery'

import DrawingImage1 from './assets/drawings/01_schlachtfeld_ballon.jpg'
import DrawingImage2 from './assets/drawings/02_MenschenHinterStacheldraht.jpg'
import DrawingImage3 from './assets/drawings/03_Skeletthuegel.jpg'
import DrawingImage4 from './assets/drawings/04_LandschaftMitErhaengten.jpg'
import DrawingImage5 from './assets/drawings/05_ToteFeldherren.jpg'
import DrawingImage6 from './assets/drawings/06_TotentanzZuDritt.jpg'
import DrawingImage7 from './assets/drawings/07_Fotografien.jpg'

function Links () {
  useEffect(() => {
    document.body.classList.add('links-detail')
    document.body.classList.add('light-mode')

    return function cleanup () {
      document.body.classList.remove('links-detail')
      document.body.classList.remove('light-mode')
    }
  }, [])

  const images = [
    {
      src: DrawingImage1
    },
    {
      src: DrawingImage2
    },
    {
      src: DrawingImage3
    },
    {
      src: DrawingImage4
    },
    {
      src: DrawingImage5
    },
    {
      src: DrawingImage6
    },
    {
      src: DrawingImage7
    }
  ]
  return (
    <>
      <div className="content">

        <div className="row first">
          <div className="row-single">
            <h2>Links</h2>
            <span className="divider"></span>
            <p className="intro">Alle 38 Zeichnungen der Reihe »Captivity« aus der Sammlung der Staatsgalerie sowie weiterführende Informationen zur Biografie und Werken Fred Uhlmans sind online zu finden unter:</p>
            <p className="intro"><a className="link" href="https://www.staatsgalerie.de/sammlung/sammlung-digital/nc/suche/_/sgs.uhlman_2021/_/0/werk/auflistung/record.html" target="_blank" rel="noreferrer">Sammlung Digital – Fred Uhlman<i className="arrow right"></i></a></p>
            <p className="intro"><a className="link" href="https://www.staatsgalerie.de/fileadmin/Webdata/Staatsgalerie/Ausstellungen/Uhlman/Fred_Uhlman_Captivity_C.Hoeper.pdf " target="_blank" rel="noreferrer">Link Aufsatz<i className="arrow right"></i></a></p>
            <p className="intro"><a className="link sbtn" href="https://www.staatsgalerie.de/ausstellungen/trotz-allem.html" target="_blank" rel="noreferrer">Veranstaltungen/Ausstellung<i className="arrow right"></i></a></p>
          </div>
        </div>

        <div className="row">
          <ResponsiveGallery
            images={images} useLightBox="true"
            numOfImagesPerRow={{ xs: 1, s: 2, m: 2, l: 2, xl: 3, xxl: 3 }}
            colsPadding={{ xs: 4, s: 4, m: 10, l: 10, xl: 10, xxl: 10 }}
            imagesPaddingBottom={{ xs: 4, s: 4, m: 15, l: 15, xl: 15, xxl: 15 }}
          />
          <div className="row-left-half">
          </div>
          <div className="row-right-half mauto">
            <p></p>
          </div>
        </div>
      </div>
    </>
  )
}
export default Links
